<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <validation-observer ref="simpleRules">
      <button
        class="btn btn-gradient-primary mb-1"
        @click="Submit()"
      >
        {{ $t('saveData') }}
      </button>
      <b-row>
        <b-col md="6">
          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ $t('parcelInfo') }}
              </h3>
            </div>

            <div class="p-2">
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-for="h-customerCode"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('customerCode') }} :
                      <span class="text-primary">
                        <i
                          v-clipboard:copy="username.username"
                          class="cursor-pointer"
                          :class="iconClass"
                          @click="handleCopy"
                        />
                      </span>
                      <span class="text-danger"> * </span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="customer code"
                      rules="required"
                    >
                      <b-input-group class="input-group-merge mb-2">
                        <!-- <b-form-input
                          id="h-customerCode"
                          v-model="items.username"
                          type="text"
                          :state="errors.length > 0 ? false:null"
                        /> -->
                        <v-select
                          id="h-customerCode"
                          v-model="username"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :state="errors.length > 0 ? false:null"
                          label="username"
                          :options="customerlist"
                          class="w-100"
                          @input="getProduct(username.id),checkedytpe()"
                        />
                        <!-- class="w-100 mr-1" -->

                        <!-- <b-input-group-append is-text>
                            <i
                              v-clipboard:copy="items.username"
                              class="cursor-pointer"
                              :class="iconClass"
                              @click="handleCopy"
                            />
                          </b-input-group-append> -->
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] ? $t('key-295'): '' }}</small>
                      <!-- <div> -->
                      <b-form-checkbox
                        v-model="items.notproduct"
                        :value="true"
                      >
                        {{ $t('ownerlessGoods') }}
                      </b-form-checkbox>
                      <!-- </div> -->

                      <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-parcel-code"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('parcelCode') }} : <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="parcel code"
                      rules="required"
                    >
                      <b-form-input
                        id="h-parcel-code"
                        v-model="items.postnumber"
                        :state="errors.length > 0 ? false:null"
                        @input="checkInput"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-purchase-order"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('key-22') }} :
                    </template>
                    <b-form-input
                      id="h-purchase-order"
                      v-model="items.tag_id"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-keyword"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('mnemonic') }} :
                    </template>

                    <b-form-input
                      id="h-keyword"
                      v-model="items.keyword"
                    />
                  </b-form-group>
                </b-col>
<!--การขนส่ง-->
<!-- {{ AccTransposort }}
{{ this.items.product_type.pro_class }} -->
         <b-col md="12">
  <b-form-group
    label-for="h-customerCode"
    label-cols="3"
    label-cols-lg="3"
    label-cols-md="3"
  >
    <template v-slot:label>
      {{ $t('transport') }} :
    </template>

    <div class="demo-inline-spacing mt-n1">
      <b-form-radio
        v-model="AccTransposort" 
        name="some-radio9"
        :value="1"
        class="custom-control-primary"
        @change="checkedytpe(AccTransposort)"
      >
        {{ $t('truck') }}
      </b-form-radio>
      <b-form-radio
        v-model="AccTransposort"
        name="some-radio9"
        :value="2"
        class="custom-control-primary"
        @change="checkedytpe(AccTransposort)"
      >
        {{ $t('ship') }}
      </b-form-radio>
    </div>
  </b-form-group>
</b-col>



                <b-col md="12">
                  <b-form-group
                    label-for="h-productType"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('productType') }} : <span class="text-danger">*</span>
                    </template> 

                    <validation-provider
                      #default="{ errors }"
                      name="product type"
                      rules="required"
                    >
                      <!-- {{ items.product_type }} -->
                      <v-select
                        id="h-productType"
                        v-model="items.product_type"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :state="errors.length > 0 ? false:null"
                        :label="Checkname()"
                        :options="ProductTypeitem"
                        class="w-100 mr-1"
                        @input="reitem()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> 

                <b-col md="12">
                  <b-form-group
                    label-for="h-checkProduct"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('woodPacking') }} :
                    </template>

                    <b-form-checkbox
                      v-model="items.packbox"
                      name="check-button"
                      class="mt-50"
                      switch
                      inline
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-checkProduct"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('checkOrder') }} :
                    </template>

                    <b-form-checkbox
                      v-model="items.checkproduct"
                      name="check-button"
                      class="mt-50"
                      switch
                      inline
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-checkProduct"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('lotSequence') }} :
                    </template>

                    <div class="mt-50">
                      <b-link :to="{name : 'admin-wherehouse-lot'}">
                        {{ items.lot_name }}
                      </b-link>/{{ items.lot_order }}
                    </div>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-remark"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('remarks') }} :
                    </template>
                    <b-form-textarea
                      id="h-remark"
                      v-model="items.remark"
                      type="text"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-remark"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('key-117') }} :
                    </template>
                    <div>
                      <span
                        v-for="imagePr in items.path_img"
                        :key="imagePr"
                        class="od-pc-offer-price-common "
                      >
                        <img
                          :src="GetImg('postnumber_img',imagePr)"
                          accept=".jpg, .png, .jpeg"
                          alt="รูปภาพ"
                          class="aws-avatar aws aws-avatar-square aws-avatar-image bordered cursor-pointer"
                          @click="showPicture('postnumber_img',items.path_img)"
                        >
                      </span>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>

          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ $t('moneyList') }}
              </h3>
            </div>

            <div class="p-2">
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-for="h-checkProduct"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('checkOrder') }} :
                    </template>
                    <b-form-input
                      id="h-checkProduct"
                      v-model="items.product_inspection_fee"
                      class="w-25"
                    />

                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-product-name"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('repackage') }} :
                    </template>
                    <b-form-input
                      id="h-product-name"
                      v-model="items.re_wrap_fee"
                      class="w-25"
                    />

                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-product-trans"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('key-114') }} :
                    </template>
                    <b-form-input
                      id="h-product-trans"
                      v-model="items.shipping_fee"
                      class="w-25"
                    />

                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-file"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('shippingAttachment') }} :
                    </template>
                    <div
                      class="clrr-setting mt-25 cursor-pointer"
                    >
                      <div class="input-file-container cursor-pointer">
                        <i class="fal fa-plus cursor-pointer mr-50" />{{ $t('key-9') }}
                        <input
                          id="img-file"
                          ref="fileInput"
                          type="file"
                          class="input-file"
                          accept="image/*"
                          multiple
                          @change="handleFileChanges"
                        >
                      </div>
                      <span
                        v-for="imagePr in items.path_file"
                        :key="imagePr"
                        class="od-pc-offer-price-common "
                      >
                        <img
                          :src="GetImg('postnumber_file',imagePr)"
                          accept=".jpg, .png, .jpeg"
                          alt="รูปภาพ"
                          class="aws-avatar aws aws-avatar-square aws-avatar-image bordered cursor-pointer"
                          @click="showPicture('postnumber_file',items.path_file)"
                        >
                        <button
                          class="btn btn-gradient-danger"
                          @click="deleteorderfile(imagePr)"
                        >
                          {{ $t('delete') }}
                        </button>
                      </span>
                    </div>

                    <!-- <div
                      v-if="imagePreviewUrls.length"
                      class="d-flex"
                    >
                      <div
                        v-for="url in imagePreviewUrls"
                        :key="url"
                        class="preview-image-container mr-25 d-flex"
                      >
                        <img
                          :src="url"
                          alt="Preview"
                          class=""
                        >
                        <div class="preview-image-overlays p-0">
                          <i
                            class="fas fa-times-circle cursor-pointer"
                            @click="removeImage(url)"
                          />
                        </div>
                      </div>
                    </div> -->
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-priceBox"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('cratePricing') }} :
                    </template>

                    <b-form-input
                      id="h-priceBox"
                      v-model="items.packaging_fee"
                      class="w-25"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-product-other"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('other') }} :
                    </template>
                    <b-form-input
                      id="h-product-other"
                      v-model="items.other"
                      class="w-25"
                    />

                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>

        <b-col md="6">
          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ $t('size') }}
              </h3>
            </div>

            <div class="p-2">
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-for="h-product-name"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('weight') }} :
                    </template>
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="basic-weight"
                        v-model="items.weight"
                        type="number"
                        placeholder="0.00"
                        @input="charge_percheck()"
                      />
                      <b-input-group-append is-text>
                        {{ $t('kilo') }}.
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-product-name"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('widthCm') }} :
                    </template>
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="basic-weight"
                        v-model="items.width"
                        type="number"
                        placeholder="0.00"
                        @input="checkedpricereq()"
                      />
                      <b-input-group-append is-text>
                        {{ $t('cm') }}
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-product-name"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('heightCm') }} :
                    </template>
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="basic-weight"
                        v-model="items.height"
                        type="number"
                        placeholder="0.00"
                        @input="checkedpricereq()"
                      />
                      <b-input-group-append is-text>
                        {{ $t('cm') }}
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-product-name"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('lengthCm') }} :
                    </template>
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="basic-weight"
                        v-model="items.long"
                        type="number"
                        placeholder="0.00"
                        @input="checkedpricereq()"
                      />
                      <b-input-group-append is-text>
                        {{ $t('cm') }}
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-parcel1"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('quantity') }} : <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="h-parcel1"
                      v-model="items.product_amount"
                      type="number"
                      class="w-25"
                      @input="checkedamount(items.product_amount)"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-parcel1"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >

                    <b-form-checkbox
                      v-model="calPriceAuto"
                      value="true"
                      @input="PriceAuto()"
                    >
                      {{ $t('autoPricing') }}
                    </b-form-checkbox>
                  </b-form-group>

                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-kilo"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ charge_per_save }} :
                    </template>
                    <b-form-input
                      id="h-kilo"
                      v-model="items.charge_per_save"
                      type="number"
                      class="w-25"
                      :disabled="calPriceAuto"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>

          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ $t('date') }}
              </h3>
            </div>

            <div class="p-2">
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-for="example-received"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('arrivedChinaWarehouse') }} :
                    </template>
                    <b-form-datepicker
                      id="example-received"
                      v-model="come_chinesewarehouse"
                      locale="th"
                      placeholder="วันที่เข้าโกดังจีน"
                    />
                    <!-- {{ moment(receivedDate).format('DD/MM/YYYY') }} -->
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="example-export"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('leftChinaWarehouse') }} :
                    </template>
                    <b-form-datepicker
                      id="example-export"
                      v-model="out_chinesewarehouse"
                      locale="th"
                      :placeholder="$t('key-27')"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="example-arrived-at"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('arrivedThaiWarehouse') }} :
                    </template>
                    <b-form-datepicker
                      id="example-arrived-at"
                      v-model="come_thaiwarehouse"
                      locale="th"
                      placeholder="วันที่เข้าโกดังไทย"
                    />
                  </b-form-group>
                </b-col>

                <!-- <b-col md="12">
                  <b-form-group
                    label-for="example-arrived-at"
                    label-cols="3"
          label-cols-lg="3"
          label-cols-md="3"
                  >
                    <template v-slot:label>
                      ถึงไทย :
                    </template>
                    <b-form-datepicker
                      id="example-arrived-thai"
                      v-model="out_thaiwarehouse"
                      locale="th"
                      placeholder="วันที่ถึงไทย"
                    />
                  </b-form-group>
                </b-col> -->
              </b-row>
            </div>
          </div>
        </b-col>

      </b-row>
    </validation-observer>
    <vue-image-lightbox-carousel
      ref="lightbox"
      :show="showLightbox"
      :images="images"
      :show-caption="false"
      @close="closeBox()"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
  BFormCheckbox,
  BFormRadio,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import moment from 'moment-timezone'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'

export default {
  components: {
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    BFormCheckbox,
    BFormRadio,
    VueImageLightboxCarousel,
  },
  data() {
    return {
      images: [],
      showLightbox: false,
      required,
      email,
      message: 'Hello world',
      checkProduct: 0,
      customerCode: '123456',
      parcelCode: null,
      purchaseOrder: null,
      re_wrap_fee: 0,
      transport: 0,
      keyword: null,
      status: null,
      remark: null,
      amount: 1,
      weight: 0,
      width: 0,
      height: 0,
      long: 0,
      ThinkPerKilo: 0,
      calPriceAuto: true,
      parcelExport: null,
      file: null,
      selectedFiles: [],
      uploadedImages: [],
      imagePreviewUrls: [],
      iconClass: 'fal fa-copy text-primary',
      ID: this.$route.params.id,
      items: [],
      receivedDate: null,
      come_chinesewarehouse: null,
      out_chinesewarehouse: null,
      come_thaiwarehouse: null,
      out_thaiwarehouse: null,
      ProductTypeitem: [],
      charge_per_save: null,
      charge_per: null,
      out_chines: null,
      come_chines: null,
      come_thai: null,
      out_thai: null,
      customerlist: [],
      username: null,
      AccTransposort : null,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
  },
  async mounted() {
    // this.receivedDate = new Date()
    await this.getData()
    this.getUserData()
  },
  methods: {
    GetImg(pathfile, filename) {
      const matches = filename.match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/)
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`
      }
      return filename
    },
    showPicture(pathfile, picture) {
      this.showLightbox = true
      // console.log(picture)
      // eslint-disable-next-line no-shadow
      picture.forEach(picture => {
        const img = { path: this.GetImg(pathfile, picture) }
        this.images.push(img)
      })
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
    checkInput() {
      if (/\s/.test(this.items.postnumber) || /\t/.test(this.items.postnumber)) {
        this.items.postnumber = this.items.postnumber.replace(/\s/g, '').replace(/\t/g, '')
      }
    },
    handleCopy() {
      this.iconClass = 'fal fa-clipboard-check text-success'

      setTimeout(() => {
        this.iconClass = 'fal fa-copy'
      }, 3500)
    },
    async getData() {
      const params = {
        ID: this.ID,
      }
      const { data: res } = await this.$http.get('/Tracking/getData', { params })
      this.items = res
      // console.debug(this.items)
      // console.log("this.items = res", this.items.product_type.pro_class)
      
      if (this.items.check_postnumber){
        this.AccTransposort = Number(this.items.product_type.pro_class)
      }else {
        this.AccTransposort = Number(this.items.transportType)
      }
      // console.log("this.AccTransposort ", this.AccTransposort)
      if (this.items.come_chinesewarehouse) {
        const parts = this.items.come_chinesewarehouse.split('/')
        this.come_chinesewarehouse = `${parts[2]}-${parts[1]}-${parts[0]}`
        // console.log(formattedDate)
      }
      if (this.items.out_chinesewarehouse) {
        const parts = this.items.out_chinesewarehouse.split('/')
        this.out_chinesewarehouse = `${parts[2]}-${parts[1]}-${parts[0]}`
      }
      if (this.items.come_thaiwarehouse) {
        const parts = this.items.come_thaiwarehouse.split('/')
        this.come_thaiwarehouse = `${parts[2]}-${parts[1]}-${parts[0]}`
      }
      if (this.items.out_thaiwarehouse) {
        const parts = this.items.out_thaiwarehouse.split('/')
        this.out_thaiwarehouse = `${parts[2]}-${parts[1]}-${parts[0]}`
      }
      this.username = {
        id: this.items.uid,
        username: this.items.username,
      }
      // this.username = this.items.username
      // moment(this.items.come_chinesewarehouse).format('DD/MM/YYYY')
      //  = this.receivedDate
      // console.log(arrr)
      this.getProduct(this.items.uid)
      if (this.items.charge_per_save === this.items.charge_per_kilo) {
        this.charge_per = 1
        this.charge_per_save = this.$t('calculateByKilo')
        // console.log('กิโล')
      } else if (this.items.charge_per_save === this.items.charge_per_cue) {
        this.charge_per = 2
        this.charge_per_save = this.$t('key-98')
      }
      // console.log(this.ID)
    },
    checkedytpe(item) {
      if (item) {
        this.items.product_type.pro_class = item
        this.getProduct(this.username.id)
        this.items.product_type = null
      } else {
        this.getProduct(this.username.id)
        this.items.product_type = null
      }
    },
    reitem() {
      this.charge_per = 1
      this.items.charge_per_kilo = 0
      this.items.charge_per_cue = 0
      this.charge_percheck()
      this.checkedpricereq()
      this.checkedamount()
    },
    async getUserData() {
      try {
        const { data: res } = await this.$http.get('/users/customer/active/lot')
        this.customerlist = res
        // console.log(this.customerlist)
      } catch (e) {
        console.log(e)
      }
    },
    async getProduct(data) {
      const params = {
        uid: data,
      }
      this.$http
        .get('/groupbyuser/show_user', { params })
        .then(response => {
          if (response.data.success) {
            const Res = response.data.data
            this.ProductTypeitem = []
            // eslint-disable-next-line no-restricted-syntax 
            for (const items of Res.product_type.filter(item => item.product_type_class == this.AccTransposort)) {
              this.ProductTypeitem.push({
                pro_id: items.product_type_id,
                pro_name: items.product_type_name,
                pro_class: items.product_type_class,
                pro_kilo: items.product_type_kilo,
                pro_queue: items.product_type_queue,
                pro_initial: items.product_type_initial,
                pro_name_en: items.product_type_name_en,
                pro_name_cn: items.product_type_name_cn,
              })
            }
            this.ProductTypeitem.sort((a, b) => {
              if (a.pro_name < b.pro_name) {
                return -1
              } if (a.pro_name > b.pro_name) {
                return 1
              }
              return 0
            })
          }
        })
        .catch(error => console.log(error))
    },
    charge_percheck() {
      if (this.calPriceAuto) {
        if (this.items.weight) {
          const sum = this.items.weight * this.items.product_type.pro_kilo * this.items.product_amount
          this.items.charge_per_kilo = this.CommasUp(sum)
          this.items.charge_per_save = this.CommasUp(this.items.charge_per_kilo)
          if (this.items.charge_per_kilo > this.items.charge_per_cue) {
            this.charge_per = 1
            this.charge_per_save = this.$t('calculateByKilo')
            this.items.charge_per_save = this.CommasUp(this.items.charge_per_kilo)
          } else if (this.items.charge_per_cue > 0) {
            this.charge_per = 2
            this.charge_per_save = this.$t('key-98')
            this.items.charge_per_save = this.CommasUp(this.items.charge_per_cue)
          }
        }
      }
    },
    checkedamount(item) {
      if (this.calPriceAuto) {
        if (item) {
          if (this.charge_per === 1) {
            const sum = this.items.weight * this.items.product_type.pro_kilo * this.items.product_amount
            this.items.charge_per_save = this.CommasUp(sum)
          }
          if (this.charge_per === 2) {
            const req = this.CommasCue(((this.items.width * this.items.height * this.items.long) / 1000000) )* this.items.product_type.pro_queue
            this.items.charge_per_save = this.CommasUp(req * this.items.product_amount)
          }
        }
      }
    },
    PriceAuto() {
      if (this.calPriceAuto) {
        if (this.items.weight) {
          const sum = this.items.weight * this.items.product_type.pro_kilo * this.items.product_amount
          this.items.charge_per_kilo = this.CommasUp(sum)
          this.items.charge_per_save = this.CommasUp(this.items.charge_per_kilo)
          if (this.items.charge_per_kilo > this.items.charge_per_cue) {
            this.charge_per = 1
            this.charge_per_save = this.$t('calculateByKilo')
            this.items.charge_per_save = this.CommasUp(this.items.charge_per_kilo)
          } else if (this.items.charge_per_cue > 0) {
            this.charge_per = 2
            this.charge_per_save = this.$t('key-98')
            this.items.charge_per_save = this.CommasUp(this.items.charge_per_cue)
          }
        }
        if (this.items.width && this.items.height && this.items.long) {
          const req = this.CommasCue(((this.items.width * this.items.height * this.items.long) / 1000000)) * this.items.product_type.pro_queue
          this.charge_per = 2
          this.items.charge_per_cue = this.CommasUp(req * this.items.product_amount)
          if (this.items.charge_per_cue > this.items.charge_per_kilo) {
            this.charge_per = 2
            this.charge_per_save = this.$t('key-98')
            this.items.charge_per_save = this.CommasUp(this.items.charge_per_cue)
          } else if (this.items.charge_per_kilo > 0) {
            this.charge_per = 1
            this.charge_per_save = this.$t('calculateByKilo')
            this.items.charge_per_save = this.CommasUp(this.items.charge_per_kilo)
          }
        }
      }
    },
    checkedpricereq() {
      if (this.calPriceAuto) {
        if (this.items.width && this.items.height && this.items.long) {
          const req = this.CommasCue(((this.items.width * this.items.height * this.items.long) / 1000000)) * this.items.product_type.pro_queue
          this.charge_per = 2
          this.items.charge_per_cue = this.CommasUp(req * this.items.product_amount) 
          if (this.items.charge_per_cue > this.items.charge_per_kilo) {
            this.charge_per = 2
            this.charge_per_save = this.$t('key-98')
            this.items.charge_per_save = this.CommasUp(this.items.charge_per_cue)
          } else if (this.items.charge_per_kilo > 0) {
            this.charge_per = 1
            this.charge_per_save = this.$t('calculateByKilo')
            this.items.charge_per_save = this.CommasUp(this.items.charge_per_kilo)
          }
        }
      }
    },
    Checkname() {
      if (this.$i18n.locale === 'th') {
        return 'pro_name'
      } if (this.$i18n.locale === 'en') {
        return 'pro_name_en'
      } if (this.$i18n.locale === 'cn') {
        return 'pro_name_cn'
      }
    },
    Submit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this.out_chinesewarehouse) {
            this.out_chines = moment(this.out_chinesewarehouse).format('DD/MM/YYYY')
          }
          if (this.come_chinesewarehouse) {
            this.come_chines = moment(this.come_chinesewarehouse).format('DD/MM/YYYY')
          }
          if (this.come_thaiwarehouse) {
            this.come_thai = moment(this.come_thaiwarehouse).format('DD/MM/YYYY')
          }
          if (this.out_thaiwarehouse) {
            this.out_thai = moment(this.out_thaiwarehouse).format('DD/MM/YYYY')
          }
          if (this.charge_per === 1) {
            this.items.charge_per_kilo = this.items.charge_per_save
          }
          if (this.charge_per === 2) {
            this.items.charge_per_cue = this.items.charge_per_save
          }
          const obj = {
            _id: this.ID,
            uid: this.username.id,
            username: this.username.username,
            postnumber: this.items.postnumber,
            purchaseOrder: this.purchaseOrder,
            keyword: this.items.keyword,
            transportType: this.items.transportType,
            product_type: this.items.product_type,
            packbox: this.items.packbox,
            checkproduct: this.items.checkproduct,
            remark: this.items.remark,
            product_inspection_fee: this.items.product_inspection_fee,
            re_wrap_fee: this.items.re_wrap_fee,
            shipping_fee: this.items.shipping_fee,
            path_file: this.items.path_file,
            packaging_fee: this.items.packaging_fee,
            other: this.items.other,
            weight: this.items.weight,
            width: this.items.width,
            height: this.items.height,
            long: this.items.long,
            product_amount: this.items.product_amount,
            charge_cue: this.CommasCue((this.items.width * this.items.height * this.items.long) / 1000000),
            charge_per_save: this.CommasUp(this.items.charge_per_save),
            charge_per_cue: this.CommasUp(this.items.charge_per_cue),
            charge_per_kilo: this.CommasUp(this.items.charge_per_kilo),
            come_chinesewarehouse: this.come_chines,
            out_chinesewarehouse: this.out_chines,
            come_thaiwarehouse: this.come_thai,
            out_thaiwarehouse: this.out_thai,
            tag_id: this.items.tag_id,
            notproduct: this.items.notproduct,
          }
          // console.log(obj)
          this.$http.post('/Tracking/UpdatePostnumber', obj)
            .then(() => {
              this.Success('บันทึกพัสดุเรียบร้อย')
              this.$router.push({ name: 'admin-tracking' })
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
            })
        }
      })
    },
    handleFileChanges(event) {
      const { files } = event.target
      // console.log(files)
      this.items.path_file = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < files.length; i++) {
        const filepath = files[i]

        const reader = new FileReader()
        reader.onload = e => {
          this.items.path_file.push(e.target.result)
        }
        reader.readAsDataURL(filepath)
      }
    },
    deleteorderfile(index) {
      this.$bvModal.msgBoxConfirm('คุณต้องการลบรูปสินค้าใช่หรือไม่?', {
        title: 'ยืนยันการลบข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(value => {
        if (value) {
          // this.delete_path = []
          const indexToRemove = this.items.path_file.indexOf(index)
          if (indexToRemove !== -1) {
            this.items.path_file.splice(indexToRemove, 1)
            this.items.path_file = null
          }
        } else {
          console.log('เกิดข้อมูลผิดพลาด')
        }
      })
    },
    addMoreImages() {
      this.$refs.fileInput.click()
      console.log(this.imagePreviewUrls)
    },
    handleFileChange(event) {
      const file = event.target.files[0]
      this.imgBackground = file
      this.previewImage(file)
    },
    previewImage(file) {
      const reader = new FileReader()
      reader.onload = () => {
        this.imagePreviewUrl = reader.result
      }
      reader.readAsDataURL(file)
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    showAdd() {
      this.$bvModal.show('add-addr')
    },
    showModal() {
      this.name = null
      this.phone = null
      this.address = null
      this.province = null
      this.district = null
      this.subdistrict = null
      this.postalcode = null
      this.$bvModal.show('modal-1')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
